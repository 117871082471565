import {Box, LinearProgress, LinearProgressProps, Typography} from "@mui/material";
import React from "react";
import styles from './LinearProgressLabel.module.css'

interface LinearProgressLabelProps extends LinearProgressProps {
    value: number,
    container?: {
        className?: string
    }
}

const LinearProgressLabel = (props: LinearProgressLabelProps): JSX.Element => {

    const containerProps = {...props.container}
    return (
        <Box className={`${styles.container}${containerProps?.className ? ` ${containerProps.className}` : ""}`}>
            <Box className={styles.percentage}>
                <Typography color="#eee" sx={{fontSize: "17px"}}>{`${Math.round(props.value,)}%`}</Typography>
            </Box>
            <Box sx={{width: "100%"}}>
                <LinearProgress variant="buffer" {...props}/>
            </Box>
        </Box>
    )
}

export default LinearProgressLabel