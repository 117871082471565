import React from "react";
import {Props} from "./interface";
import styles from './Section.module.css'
import {getSizes} from "../../../../helpers/functions";

const Section = (props: Props): JSX.Element => {

    const {config} = props

    /************************************
     *           CSS STYLE
     * *********************************/
    const containerStyle = {
        "--background-color": props.config?.background?.color,
        "--border-color": props.config?.border?.color
    } as React.CSSProperties

    /************************************
     *      INLINE TITLE STYLE
     * *********************************/
    const titleStyle = {
        fontWeight: config.title?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.title?.style?.size),
        color: config.title?.color,
    } as React.CSSProperties

    /************************************
     *     INLINE SUBTITLE STYLE
     * *********************************/
    const subTitleStyle = {
        fontWeight: config.subTitle?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.subTitle?.style?.size),
        color: config.subTitle?.color,
    } as React.CSSProperties


    return (
        <div className={styles.container} style={containerStyle}>
            {config?.title && <h1 style={titleStyle}>{config.title?.locale}</h1>}
            {config?.subTitle && <p style={subTitleStyle}>{config?.subTitle?.locale}</p>}
            {props.children}
        </div>
    )
}

export default Section