import React, {useEffect, useState} from "react";
import styles from './PendingUploads.module.css'
import {LinearProgress} from "@mui/material";
import ActiveRequests from "../../models/active-requests";
import {UseHttpResponse} from "../../hooks/use-http/interfaces";
import DynamicObject from "../../models/dynamic-object";
import {t} from "i18next";

interface NameProps {
    children?: React.ReactNode;
    totalRequests: number,
    publishApi: UseHttpResponse<DynamicObject>
}

let eventIsAdded = false
const Name = (props: NameProps): JSX.Element => {
    const {totalRequests, publishApi} = props
    const [successRequests, setSuccessRequests] = useState<number>(0)
    // useEffect(() => {
    //
    // }, [successRequests]);

    let urls = []

    useEffect(() => {
        if (eventIsAdded)
            return

        const UploadRequestsHandler = (e: any) => {

            setSuccessRequests((currentSuccessRequests) => {
                return totalRequests - e.detail
            })
        }
        window.addEventListener("upload-requests", UploadRequestsHandler)
        eventIsAdded = true

        return () => {
            eventIsAdded = false
            window.removeEventListener("upload-requests", UploadRequestsHandler)
        };
    }, [])

    useEffect(() => {
        if (successRequests < totalRequests) {
            return
        }


        setTimeout(function (){
            publishApi.request()
        }, 2000)

    }, [successRequests])

    const progress = (successRequests / totalRequests) * 100


    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1 className={styles.title}>{t("uploadingImages")}</h1>
                <p className={styles.paragraph}>{t("stillUploadingAdImages")}</p>
            </div>
            <div className={styles.progress_container}>
                <div className={styles.progress_header}>
                    <span className={styles.uploading}>{t("uploading")}</span>
                    <span>{successRequests}/{totalRequests}</span>
                </div>
                <LinearProgress variant="determinate" value={progress} sx={{
                    borderRadius: "8px",
                    height: "7px"
                }}/>
            </div>
        </div>
    )
}

export default Name