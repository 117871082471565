import {Props} from './interfaces';
import React from "react";
import {Checkbox as CheckboxMui} from "@mui/material";
import {FormControlLabel, FormGroup} from "@mui/material";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import ValidationError from "../../../UI/ValidationError/ValidationError";

const Checkbox = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const {state, validation} = useDataPayload<string>({
        ref,
        config: props.config
    })
    const {config} = props


    {/*ACTION HANDLER*/
    }
    const addItemHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        state.set(e.target.checked?'1':'0');
    }


    return (
        <div className={`mt-2 mb-5`}>
            <FormGroup>
                <FormControlLabel
                    control={
                        <CheckboxMui
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => addItemHandler(e)}
                            checked={state.value === '1'}
                        />
                    }
                    label={config.text.locale}
                    value={state.value === '1'}
                />
            </FormGroup>
            <ValidationError validation={validation}/>
        </div>
    )
})

export default Checkbox