import React from "react";
import styles from './CardSlider.module.css'
import {getSizes} from "../../../../helpers/functions";
import CardSliderItem from "../../../../models/templates/card-slider-item";

interface Props {
   info:CardSliderItem
}

const Item = (props:Props) => {

    const title = props.info.title.locale
    const subTitle = props.info.subTitle.locale
    const titleFontSize = getSizes(props.info.title.style?.size)
    const titleBoldClass = props.info.title.style?.bold?'fw-bold':''
    const subTitleFontSize = getSizes(props.info.subTitle.style?.size)
    const subTitleBoldClass = props.info.subTitle.style?.bold?'fw-bold':''

    return (
        <div>
            <div className={`${styles.container} rounded`} style={{background: props.info.background?.color}}>
                <div className={`row ${styles.card_row}`}>
                    <div className={`col-md-8`}>
                        <h2 style={{color: props.info.title.color,fontSize:titleFontSize}} className={titleBoldClass}>{title}</h2>
                        <p className={`${styles.description} ${subTitleBoldClass}`}
                           style={{color: props.info.subTitle.color,fontSize:subTitleFontSize}}>{subTitle} </p>
                    </div>

                    <div className={`col-md-4 ${styles.img_container}`}>
                        <div className={styles.img}>
                            <img  style={{width: 100,}}src={props.info.imagePath}
                                 className={`rounded-circle img-fluid`}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Item