import React, {useContext} from "react";
import {Props} from "./interface";
import styles from './ListRadioOptions.module.css'
import PostContext from "../../../../storage/PostContext";
import DynamicObject from "../../../../models/dynamic-object";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";

const ListRadioOptions = (props: Props) => {

    const {config} = props

    /************************************
     *            CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)

    /*-------------------------------------
     *           FUNCTIONS
     * ----------------------------------*/

    /************************************
     *        ACTION HANDLER
     * *********************************/

    const addItemHandler = (id: number | string, label: string) => {
        postCtx.addToForm(config.identifier, id)

        const previewConfig: DynamicObject = postCtx.previewStep
        const lastPreview = previewConfig.childs.filter((child: any) => {
            if (child.identifier === config.identifier) {
                const values = child.values
                values[0] = {...values[0], label: label, id: id}
                return {
                    ...child,
                    values: [...values]
                }
            } else {
                return child
            }
        })

        const finalConfig = {
            ...previewConfig,
            childs: lastPreview
        }
        props.setConfigData(finalConfig);
    }
    /* ------------------------------------*/

    /************************************
     *    GET ITEMS AS A COMPONENT
     * *********************************/
    const items = config?.values?.map((item, i) => {
        return (
            <div className={`${styles.item}cursor__pointer`}
                 onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item.id, item.label)}>
                <FormControlLabel control={<Radio/>}
                                  label={item.label} value={item.id}/>
            </div>)
    });

    return (
        <div

        >
            <div className={`${styles.body} rounded fw-bold`}>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                    }}

                >
                    {
                        items
                    }
                </RadioGroup>
            </div>
        </div>
    )
}
export default ListRadioOptions