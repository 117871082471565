import React, {useContext} from "react";
import {Props} from "./interface";
import styles from './RedirectBtn.module.css'
import {getSizes} from "../../../../helpers/functions";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import languageContext from "../../../../storage/LanguageContext";


const RedirectBtn = (props:Props) => {

    const langCtx=useContext(languageContext)

    /************************************
     *           CSS STYLES
     * *********************************/
    const containerStyle = {
        "--title-font-weight": props.config.title.style?.bold ? "bold" : "normal",
        "--title-font-size": getSizes(props.config.title.style?.size),
        "--title-font-color": props.config.title.color,
        "--subtitle-font-weight": props.config.subTitle.style?.bold ? "bold" : "normal",
        "--subtitle-font-size": getSizes(props.config.subTitle.style?.size) ,
        "--subtitle-font-color": props.config.subTitle.color,
    }as React.CSSProperties

    return (<div
        className={`${styles.container} justify-content-between`}
        style={containerStyle}

    >
        <div className={`${styles.box}`}>
            <div className={`row`}>
                <div className={`col-md-12`}>
                    <h1 className={`${styles.title}`}>{props.config.title.locale}</h1>
                </div>
            </div>
            <div className={`row mt-3`}>
                <div className={`col-md-6`}>
                    <h6 className={`${styles.subtitle}`}>{props.config.subTitle.locale}</h6>
                </div>
                <div className={`col-md-6 ${styles.icon}`}>
                    {(langCtx.language == 'en') ? <IoIosArrowForward/> :  <IoIosArrowBack/>}
                </div>
            </div>
        </div>


    </div>)
}

export default RedirectBtn











