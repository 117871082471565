import React from "react";
import Item from "./Item";
import {Props} from "./interface";
import Carousel from 'react-material-ui-carousel'

const CardSlider = (props: Props) => {

    return (<div style={{width: "100%"}}>
        <Carousel navButtonsAlwaysInvisible={true}>
            {
                props.config.cards.map((item, i) => <Item info={item}/>)
            }
        </Carousel>
    </div>)
}
export default CardSlider















