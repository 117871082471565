import React from "react";
import './PaymentWidget.css'
import {Props} from "./interface";

const PaymentWidget  = (props:Props) => {
    return (
        <div
            className={`payment-widget__container`}>
        </div>
    )
}
export default PaymentWidget














