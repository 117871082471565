import React from "react";
import {Props,RefDataType} from './interfaces';
import styles from './UploadImage.module.css'
import {getSizes} from "../../../../helpers/functions";
import {LinearProgressLabel} from "../../../UI";

const UploadImage = React.forwardRef<RefDataType, Props>((props, ref) => {

    const containerStyle = {
        "--text-font-weight": props.config.text?.style?.bold ? "bold" : "normal",
        "--text-font-size": getSizes(props.config.text?.style?.size) ,
        "--text-font-color": props.config.text.color,
        "--background-color": props.config?.background?.color,
    } as React.CSSProperties

    return (
        <div
            className={`${styles.container}`}
            style={containerStyle}>
            <div className={`row ${styles.row}`}>
                {/*HEADER TITLE*/}
                <div className={`col-md-8 ${styles.text}`}>
                    <h2>{props.config.text.locale}</h2>
                </div>

                {/* IMAGE*/}
                <div className={`col-md-4 `}>
                    <div className={`${styles.image_container}`}>
                        <img className={styles.image}
                             src={"https://cdn5.vectorstock.com/i/1000x1000/02/29/camera-icon-blue-monochrome-color-vector-23770229.jpg"}/>


                    </div>
                </div>
            </div>
        </div>
    )
})

export default UploadImage