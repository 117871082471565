interface String {
    capitalize(this: string): string;
    format(...args): string;
}

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
};

String.prototype.format = function (...args) {
    args = arguments;
    return this.replace(/{([0-9]+)}/g, function (match, index) {
        return typeof args[index] == 'undefined' ? match : args[index];
    });
};