import ArachnaApi from './requests/arachna-api'
import {AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";
import {AddPostFilterResponse} from "../interceptors";

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    const request = ArachnaApi.getInstance().request
    const lang = document.cookie.replace(/(?:(?:^|.*;\s*)lang\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    request.interceptors.response.use(AddPostFilterResponse().success,  AddPostFilterResponse().error)
    return request.post(`${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/cv/save-data/${config?.workflow_id}/${config?.step}`, {
        draftId: config?.draftId,
        workflowId: config?.workflow_id,
        time_spent_ms:config?.time_spent_ms,
        step:config?.step,
        ...config?.data
    }, {
        headers: {
            ['enforce-language']: config?.language,
            ['content-type']: "multipart/form-data"
        }
        }
    )
}