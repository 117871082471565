import React, {useContext, useEffect, useImperativeHandle, useState} from "react";
import styles from './Map.module.css'
import {useTranslation} from "react-i18next";
import {Button as MuiButton} from "@mui/material";
import {GoogleMap} from "../../../../utils/google-maps";
import PostContext from "../../../../storage/PostContext";
import {Coordinates, Props, RefDataType} from "./interface";
import DynamicObject from "../../../../models/dynamic-object";

const Map = React.forwardRef<RefDataType, Props>((props, ref) => {

    const {config, saveHttp} = props

    /************************************
     *         TRANSLATION
     * *********************************/
    const {t} = useTranslation();

    /************************************
     *            CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)


    /************************************
     *            CONSTANTS
     * *********************************/
    const defaultCoordinates = postCtx.data.form[props.config.identifier] ? postCtx.data.form[props.config.identifier] : props.config.center

    let [configLat, configLng] = defaultCoordinates.split(",", 2)

    /************************************
     *             STATES
     * *********************************/
    const [coordinates, setCoordinates] = useState<Coordinates>({
        lat: parseFloat(configLat),
        lng: parseFloat(configLng)
    })


    /*-------------------------------------
     *            FUNCTIONS
     * ----------------------------------*/

    /************************************
     *        ACTION HANDLERS
     * *********************************/

    const changeLatitudeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCoordinates((old: Coordinates) => {
            return {
                ...old,
                lat: parseFloat(e.target.value)
            }
        })
    }

    const changeLongitudeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCoordinates((old: Coordinates) => {
            return {
                ...old,
                lng: parseFloat(e.target.value)
            }
        })
    }

    const clearMap = () => {
        const httpConfig: DynamicObject = {
            config: {
                data: {
                    [`${config.identifier}`]: defaultCoordinates,
                }
            }
        }

        saveHttp?.request(httpConfig)
    }

    const addItemHandler = () => {
        const httpConfig: DynamicObject = {
            config: {
                data: {
                    [`${config.identifier}`]: `${coordinates.lat},${coordinates.lng}`,
                }
            }
        }

        saveHttp?.request(httpConfig)
    }
    /*------------------------------------*/

    /************************************
     *   GET INNER STATES USING REFS
     * *********************************/
    useImperativeHandle<RefDataType, RefDataType>(ref, () => {
        return {
            getState: (): any => `${coordinates.lat},${coordinates.lng}`
        }
    })


    /************************************
     *           MAP LOADER
     * *********************************/
    useEffect(function () {
        const googleMap = new GoogleMap("google-map")
        googleMap.loadMap({
            zoom: config.zoomLevel
        }).then(function () {
        })
    }, [])


    return (
        <div className={`${styles.container} mt-3`}>
            <div className={styles.map_container}>
                <div className={'google-map'} style={{width: "100%", height: "70vh", overflow: "unset"}}/>

                <input type="hidden" className={'lat'} onInput={changeLatitudeHandler} value={coordinates.lat}/>
                <input type="hidden" className={'lng'} onInput={changeLongitudeHandler} value={coordinates.lng}/>
            </div>
            <div className="row">
                <div className={`col-12 ${styles.button_container}`} style={{
                    display: props.config.skip ? "flex" : "",
                    flexDirection: "row",
                    flexWrap: "nowrap"
                }}>
                    <MuiButton
                        fullWidth={true}
                        style={{
                            borderRadius: "0px",
                            color: "#fff",
                            backgroundColor: "#0c5cf9",
                            fontWeight: "bold",
                            fontSize: "18px",
                            height: "50px"

                        }}
                        onClick={(e) => addItemHandler()}
                    >{t('save')}</MuiButton>
                    {props.config.skip && <MuiButton style={{
                        borderRadius: "0px",
                        color: "#fff",
                        backgroundColor: "#505050",
                        fontWeight: "bold",
                        fontSize: "18px",
                        height: "50px"
                    }}
                                                     fullWidth={true}

                                                     onClick={(e) => clearMap()}
                    >{t('skip')}</MuiButton>}
                </div>

            </div>

        </div>
    )
})

export default Map