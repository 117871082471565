import React, {useContext, useImperativeHandle, useState} from "react";
import styles from "./TextField.module.css";
import {Props, RefDataType} from "./interface";
import PostContext from "../../../../storage/PostContext";
import languageContext from "../../../../storage/LanguageContext";
import {getFieldType, getInputType, getSizes, toEnDigit} from "../../../../helpers/functions";
import {FormHelperText, TextField as TextFieldMUI} from "@mui/material";
import useValidationNew from "../../../../hooks/use-validation/use-validation-new";

const TextField = React.forwardRef<RefDataType, Props>((props, ref) => {

    /************************************
     *              CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)
    const langCtx = useContext(languageContext)

    /************************************
     *              STATES
     * *********************************/
    const [value, setValue] = useState<string>(postCtx.data.form[props.config.identifier] ?? '')

    /*---------------------------------------------------------------
     *                            HOOKS
     * --------------------------------------------------------------*/

    /************************************
     *     GET AND CLEAR VALIDATION
     * *********************************/
    const {error, setValidationError, clearValidationError} = useValidationNew()

    /*---------------------------------------------------------------*/

    /************************************
     *            CONSTANTS
     * *********************************/
    const inputType = getInputType(props.config.format.toLowerCase() )
    const alignCenter = (langCtx.language == 'en') ? 'left' : 'right'
    const type = getFieldType(props.config.format.toLowerCase() )

    /************************************
     *        ACTION HANDLER
     * *********************************/
    const textHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        clearValidationError()
        if (props.config.format.toLowerCase()  == 'float') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*\.?[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*$/g.test(e.target.value) || e.target.value=='') {
                setValue(toEnDigit(e.target.value));
            }
        } else if (props.config.format.toLowerCase() == 'int') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]+$/g.test(e.target.value) || e.target.value=='') {
                setValue(toEnDigit(e.target.value));
            }
        } else {
            setValue(toEnDigit(e.target.value));
        }
    }


    const handleKey = (event) => {
        let regExp = new RegExp(/[\u0660-\u0669\u06f0-\u06f9]/g)
        if (props.config.format.toLowerCase() == 'float' && (event.which >= 48 && event.which <= 57 || event.which == 46 || regExp.test(event.key))) {
            if (event.target?.value.includes('.') && event.which == 46) {
                event.preventDefault()
            }
            setValue(toEnDigit(event.target.value));
        } else if (props.config.format.toLowerCase() == 'int' && (event.which >= 48 && event.which <= 57 || regExp.test(event.key))) {
            setValue(toEnDigit(event.target.value));
        } else if (props.config.format.toLowerCase() == 'all'|| props.config.format.toLowerCase() == 'open') {
            setValue(toEnDigit(event.target.value));
        } else {
            event.preventDefault();
        }
    }

    /************************************
     *       PREVENT SCROLLING
     * *********************************/
    document.addEventListener("wheel", function (event) {
        if (type === "number")
            document.getElementById("outlined-text")?.blur()

    });
    /************************************
     *   GET INNER STATE USING REFS
     * *********************************/
    useImperativeHandle<RefDataType, RefDataType>(ref, () => {
        return {
            getState: (): string => value,
            setValidationError,
            scrollIntoView: () => {
            }
        }
    })
    /************************************
     *       SET ATTRIBUTES
     * *********************************/
    const attributes = {
        id: "outlined-text",
        fullWidth: true,
        maxRows: 4,
        placeholder: props.config.placeholder.locale,
        InputLabelProps: {
            style: {
                color: props.config.placeholder.color,
                fontWeight: props.config.placeholder.style?.bold ? 'bold' : 'normal',
                fontSize: getSizes(props.config.placeholder.style?.size),
            }
        },
        InputProps: {
            style: {
                color: props.config.text.color,
                fontWeight: props.config.text?.style?.bold ? 'bold' : 'normal',
                fontSize: window.innerWidth <= 768 ? '18px' : getSizes(props.config.text?.style?.size),
            },
            readOnly: !props.config.editable,

        }
    }

    return (
        <>
            <div className={`mt-2  ${styles.container}`} style={{display: "flex"}}>

                    <TextFieldMUI {...attributes}
                                  value={value} variant={'outlined'}
                                  inputProps={{maxLength: 100}}
                                  inputMode={inputType}
                                  autoComplete={"off"}
                                  autoCorrect={"off"}
                                  spellCheck={"false"}
                                  onChange={textHandler}
                                  onPaste={(event) => {
                                      if (type != 'text') {
                                          event.preventDefault()
                                      }
                                  }}
                                  onCopy={(event) => {
                                      if (type != 'text') {
                                          event.preventDefault()
                                      }
                                  }}
                                  onCut={(event) => {

                                      if (type != 'text') {
                                          event.preventDefault()
                                      }
                                  }}
                                  onKeyPress={handleKey}

                    />
            </div>
            {error && <FormHelperText style={{
                color: "red",
                fontSize: "15px",
                paddingTop: "2px",
                textAlign: alignCenter

            }}>
                {`*  ${error}`}
            </FormHelperText>}
        </>

    )
})

export default TextField