
export interface SectionConfig {
    [key: string]: () => void
}

export default class SectionCacher {

    private _section: SectionConfig
    static instance: SectionCacher


    private constructor() {
        this._section = {}
    }

    static getInstance() {
        if (!this.instance)
            this.instance = new SectionCacher()

        return this.instance
    }

    static clearInstance() {
        return this.instance=null
    }

    get(id: string) {
        return this._section[id]
    }


    update(id: string, section:() => void) {
        this._section[id] = section
    }

}
