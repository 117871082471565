import styles from './Map.module.css'
import NextButton from "../../NextButton/NextButton";
import {GoogleMap} from "../../../../utils/google-maps";
import {Props, Coordinates} from './interfaces';
import React, {useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import PostContext from "../../../../storage/PostContext";
import {useTranslation} from "react-i18next";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import DynamicObject from "../../../../models/dynamic-object";
import {Button as MuiButton} from "@mui/material";
import nextApi from "../../../../api/next.api";


const Map = React.forwardRef<RefDataType<string>, Props>((props, ref) => {
    const {state, validation} = useDataPayload<string>({
        ref,
        config: props.config
    })

    const {config, nextApi} = props
    const postCtx = useContext(PostContext)
    const {t} = useTranslation();
    const defaultCoordinates = postCtx.data.form[props.config.identifier] ? postCtx.data.form[props.config.identifier] : props.config.center
    let [configLat, configLng] = defaultCoordinates.split(",", 2)

    const [coordinates, setCoordinates] = useState<Coordinates>({
        lat: parseFloat(configLat),
        lng: parseFloat(configLng)
    })


    {/*ACTION HANDLERS*/
    }
    const changeLatitudeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCoordinates((old: Coordinates) => {
            return {
                ...old,
                lat: parseFloat(e.target.value)
            }
        })
        state.set(`${coordinates.lat},${coordinates.lng}`)
    }

    const changeLongitudeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCoordinates((old: Coordinates) => {
            return {
                ...old,
                lng: parseFloat(e.target.value)
            }
        })
        state.set(`${coordinates.lat},${coordinates.lng}`)
    }

    useEffect(function () {
        const googleMap = new GoogleMap("google-map")
        googleMap.loadMap({
            zoom: config.zoomLevel
        }).then(function () {
        })
    }, [])

    const addItemHandler = () => {
        const httpConfig: DynamicObject = {
            config: {
                data: {
                    [`${config.identifier}`]: `${coordinates.lat},${coordinates.lng}`,
                }
            }
        }
        httpConfig["config"]["data"][config.identifier] = `${coordinates.lat},${coordinates.lng}`

        nextApi?.request(httpConfig)
    }

    const clearMap = () => {
        setCoordinates({lat: undefined, lng: undefined})
        const httpConfig: DynamicObject = {
            config: {
                data: {
                    [`${config.identifier}`]: ``,
                }
            }
        }
        httpConfig["config"]["data"][config.identifier] = ','

        nextApi?.request(httpConfig)
    }

    return (
        <div className={`${styles.container} mt-3${props.className ? ` ${props.className}` : ''}`}>
            <div className={styles.map_container}>
                <div className={'google-map'}
                     style={{width: "100%", height: "65vh", overflow: "unset", marginBottom: "100px"}}/>
                <input type="hidden" className={'lat'} onInput={changeLatitudeHandler} value={coordinates.lat}/>
                <input type="hidden" className={'lng'} onInput={changeLongitudeHandler} value={coordinates.lng}/>
            </div>
            <div className={`row`}>
                <div className={`col-12 ${styles.button_container}`} style={{
                    display: props.config.skip ? "flex" : "",
                    flexDirection: "row",
                    flexWrap: "nowrap"
                }}>
                                   <MuiButton
                        fullWidth={true}
                        style={{
                            borderRadius: "0px",
                            color: "#fff",
                            backgroundColor: "#0c5cf9",
                            fontWeight: "bold",
                            fontSize: "18px",
                            height: "50px"

                        }}
                        onClick={(e) => addItemHandler()}
                    >{t('save')}</MuiButton>
                    {props.config.skip && <MuiButton style={{
                        borderRadius: "0px",
                        color: "#fff",
                        backgroundColor: "#505050",
                        fontWeight: "bold",
                        fontSize: "18px",
                        height: "50px"
                    }}
                                                     fullWidth={true}

                                                     onClick={(e) => clearMap()}
                    >{t('skip')}</MuiButton>}</div>

            </div>
            <ValidationError validation={validation}/>
        </div>
    )
})

export default Map