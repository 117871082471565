import {AxiosRequestConfig} from "axios";
import {useContext} from "react";
import LanguageContext from "../storage/LanguageContext";
import i18n from "../i18n";
import {appConfig} from "../config";


export default (config: AxiosRequestConfig) : AxiosRequestConfig => {
    // localStorage.setItem("platform", window.screen.width <= 700 ? appConfig.SOURCES_KEYS.MOBILE : appConfig.SOURCES_KEYS.DESKTOP)
    return config
}