import React, {useContext, useEffect, useImperativeHandle, useState} from "react";
import {Props, RefDataType} from "./interface";
import styles from './GridCheckboxOptions.module.css'
import PostContext from "../../../../storage/PostContext";
import DynamicObject from "../../../../models/dynamic-object";
import languageContext from "../../../../storage/LanguageContext";
import useValidationNew from "../../../../hooks/use-validation/use-validation-new";
import {Checkbox, FormControlLabel, FormGroup, FormHelperText} from "@mui/material";

const GridCheckboxOptions = React.forwardRef<RefDataType
    , Props>((props, ref) => {


    /************************************
     *            CONTEXT
     * *********************************/
    const langCtx = useContext(languageContext)
    const postCtx = useContext(PostContext)

    /************************************
     *             STATES
     * *********************************/
    const [value, setValue] = useState<string>(postCtx.data.form[props.config.identifier] as string ?? '')

    /*----------------------------------------------------------------
     *                           HOOKS
     * --------------------------------------------------------------*/

    /************************************
     *   SET & GET VALIDATION AND STATES
     * *********************************/

    const {error, setValidationError, clearValidationError} = useValidationNew()

    /*---------------------------------------------------------------*/

    /************************************
     *            CONSTANTS
     * *********************************/
    const alignCenter = (langCtx.language == 'en') ? 'left' : 'right'
    const checkedItems: DynamicObject = {}


    /*----------------------------------------------------------------
     *                           FUNCTIONS
     * --------------------------------------------------------------*/
    useImperativeHandle<RefDataType, RefDataType>(ref, () => {
        return {
            getState: (): string => {
                return value
            },
            setValidationError
        }
    })

    /************************************
     *        ACTION HANDLER
     * *********************************/
    const addItemHandler = (itemValue: string) => {

        if (!value) {
            setValue(itemValue)
            return
        }
        setValue((old: string) => {
            return old ? `${old},` : '' + value
        })
    }

    /************************************
     *         UNCHECK ITEMS
     * *********************************/
    const removeItemHandler = (itemValue: string) => {
        const oldItems = value.toString().split(',')
        const newItems = oldItems.filter((item: string) => {
            return itemValue !== item
        })
        setValue(newItems.join(',') ?? '')
    }

    const changeItem = (e: React.ChangeEvent<HTMLInputElement>) => {
        clearValidationError()
        if (!e.target.checked) {
            removeItemHandler(e.target.value)
            return
        }

        addItemHandler(e.target.value)
    }

    value.toString().split(',')?.map((item: string) => {
        checkedItems[item] = true
    })

    /*---------------------------------------------------------------*/

    /************************************
     *      GET ITEMS AS COMPONENT
     * *********************************/
    const items = props.config.values?.map((item) => {
        return <FormGroup>
            <FormControlLabel control={<Checkbox inputProps={{'value': item.id}} size={"medium"}
                                                 onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeItem(e)}/>}
                              checked={!!checkedItems[item.id]}
                              labelPlacement={"end"} label={item.title?.locale}/>

        </FormGroup>

    })

    return (
        <div className={`row justify-content-center p-1`}>
            <div className={`col-12 ${styles.col} justify-content-center`}>
                <div className={`${styles.checkbox_pill} m-1 p-2`}></div>
                {items}
            </div>
            {error && <FormHelperText style={{
                color: "red",
                fontSize: "18px",
                paddingTop: "2px",
                textAlign: alignCenter
            }}>{`*   ${error}`}</FormHelperText>
            }
        </div>
    )
})

export default GridCheckboxOptions