import React from "react";
import {Props} from "./interface";
import {FormControl} from "@mui/material";
import {IoIosArrowForward} from "react-icons/io";
import styles from './RedirectSelectBtn.module.css'
import {getSizes} from "../../../../helpers/functions";

const RedirectSelectBtn = (props: Props) => {
    const {config} = props

    /************************************
     *       INLINE TITLE STYLE
     * *********************************/
    const titleStyle = {
        color: config.title.color,
        fontSize: getSizes(config.title.style?.size),
        fontWeight: config.title.style?.bold ? "bold" : "normal",
    }
    /************************************
     *     INLINE SUBTITLE STYLE
     * *********************************/
    const subTitleStyle = {
        color: config.subTitle.color,
        fontSize: getSizes(config.subTitle.style?.size),
        fontWeight: config.subTitle.style?.bold ? "bold" : "normal",
    }


    return (
        <div className={`${styles.container}`}
        >
            <FormControl fullWidth>
                <div className={`${styles.btn}`}
                     onClick={(e: React.MouseEvent<HTMLElement>) => {
                     }}
                >
                    <div className={`${styles.text} col-lg-10 col-md-8 col-sm-8`}>
                        <h1 style={titleStyle}>{config.title.locale}</h1>
                        <p style={subTitleStyle}>{config.subTitle.locale}</p>
                    </div>
                    <div className={`${styles.icon} col-lg-2 col-md-4 col-sm-4`}>
                        <IoIosArrowForward style={{height: "21.2px", width: "22.85px", padding: "0 10px"}}/>
                    </div>
                </div>
            </FormControl>
        </div>
    )
}

export default RedirectSelectBtn