import {AxiosError, AxiosResponse} from "axios";

interface Result {
    success: (response: AxiosResponse) => any,
    error: (error: AxiosError) => AxiosError,
}

export default (): Result => {
    return {
        success: (response: AxiosResponse) => {


            if (response.status==200||response.status==201)
            {
                response.data = {
                    ...response.data.result.data
                }
                return response

            }
            return Promise.reject(response)

        },
        error: (error: AxiosError) => {

            return error
        }
    }
}