import ArachnaApi from './requests/arachna-api'
import {AxiosInstance, AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";
import {AddPostFilterResponse} from "../interceptors";

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    const request = ArachnaApi.getInstance().request as AxiosInstance
    request.interceptors.response.use(AddPostFilterResponse().success, AddPostFilterResponse().error)
    const params = {
        draftId: config?.data.draftId,
    }
    return request.get(`${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/${config?.data.flow_type}/normal/${config?.data.currentStep}/${config?.data.workflowId}`, {
        params: {
            ...params,
        },
    })
}