import React, {useContext} from "react";
import DynamicObject from "../../../models/dynamic-object";
import {UseHttpResponse} from "../../../hooks/use-http/interfaces";
import {Button as MuiButton} from "@mui/material";
import {getSizes} from "../../../helpers/functions";
import UIContext from "../../../storage/UIContext";
import styles from "./NextButtonModal.module.css"

interface NextButtonProps {
    nextApi?: UseHttpResponse<DynamicObject>;
    style?: {
        bold?: boolean,
        size?: string,
        color?: string,
        backgroundColor?: string
        borderRadius?: string
        marginTop?: string
    },
    children?: React.ReactNode;
    className?: string
    onClick?: Function
}


const NextButtonModel = (props: NextButtonProps): JSX.Element => {
    const {nextApi, style, children} = props

    const uiCtx=useContext(UIContext)

    const submitStyle = {
        fontWeight: style?.bold ? "bold" : "normal",
        fontSize: getSizes(style?.size || "m"),
        width: uiCtx.showModal?"50vw":"100%",
        height: "50px",
        borderRadius: style?.borderRadius ? style.borderRadius : "8px",
        color: style?.color,
        backgroundColor: style?.backgroundColor,


    } as React.CSSProperties


    const nextHandler = () => {
        if (props.onClick) {
            props.onClick()
        }

        const httpConfig: DynamicObject = {}
        nextApi?.request(httpConfig)
    }

    return (
            <MuiButton variant='contained' style={submitStyle}
                       className={`d-block`}
                       onClick={(e: React.MouseEvent<HTMLElement>) => nextHandler()}>{children}
            </MuiButton>

    )
}

export default NextButtonModel