import React, {useContext, useImperativeHandle, useState} from "react";
import styles from "./TextArea.module.css";
import {Props, RefDataType} from "./interface";
import {getSizes} from "../../../../helpers/functions";
import PostContext from "../../../../storage/PostContext";
import languageContext from "../../../../storage/LanguageContext";
import {FormHelperText, TextField as TextFieldMUI} from "@mui/material";
import useValidationNew from "../../../../hooks/use-validation/use-validation-new";

const TextArea = React.forwardRef<RefDataType, Props>((props, ref) => {

    /************************************
     *              CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)
    const langCtx=useContext(languageContext)

    /************************************
     *              STATES
     * *********************************/
    const [value, setValue] = useState<string>(postCtx.data.form[props.config.identifier] ?? '')
    const [count, setCount] = useState(value.length ?? 0);

    /*---------------------------------------------------------------
    *                            HOOKS
    * --------------------------------------------------------------*/

    /************************************
     *     GET AND CLEAR VALIDATION
     * *********************************/
    const {error, setValidationError, clearValidationError} = useValidationNew()

    /*---------------------------------------------------------------*/

    /************************************
     *            CONSTANTS
     * *********************************/
    const alignCenter = (langCtx.language == 'en') ? 'left' : 'right'

    /************************************
     *        ACTION HANDLER
     * *********************************/
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        clearValidationError()
        setCount(e.target.value.length)
        setValue(e.target.value)
    }

    /************************************
     *   GET INNER STATE USING REFS
     * *********************************/
    useImperativeHandle<RefDataType, RefDataType>(ref, () => {
        return {
            getState: (): string => value,
            setValidationError
        }
    })

    /************************************
     *       SET ATTRIBUTES
     * *********************************/
    const attributes = {
        id: "outlined-multiline-static",
        fullWidth: true,
        multiline: true,
        rows: props.config.height,
        placeholder: props.config.placeholder.locale,
        InputProps: {
            style: {
                color: props.config.text.color,
                fontWeight: props.config.text?.style?.bold ? 'bold' : 'normal',
                fontSize: window.innerWidth<=768?'18px':getSizes(props.config.text?.style?.size),
            },
            readOnly:!props.config.editable,

        },


    }

    return (
        <div className={`mt-3 mb-5 ${styles.container}`}>
            <TextFieldMUI className={styles.text_area} {...attributes} onChange={onChange}
                          value={value} inputProps={{maxLength: props.config.limit}}

            />
            {props.config.counter &&  <p style={{
                marginTop: "-20px",
                marginRight: "8px",
                marginLeft: "8px",
                opacity: "0.4",
                fontSize: "10px",
                display: "flex",
                justifyContent: "flex-end"
            }}>{`${count} / ${props.config.limit}`}</p>
            }
            {error && <FormHelperText style={{
                color: "red",
                fontSize: "15px",
                paddingTop: "2px",
                textAlign: alignCenter

            }}>
                {`*  ${error}`}
            </FormHelperText>

            }

        </div>
    )
})

export default TextArea

