import ArachnaApi from './requests/arachna-api'
import {AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";
import {AddPostFilterResponse} from "../interceptors";
import {useContext} from "react";
import languageContext from "../storage/LanguageContext";

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    const request = ArachnaApi.getInstance().request
    const langCtx =useContext(languageContext)
    request.interceptors.response.use(AddPostFilterResponse().success,  AddPostFilterResponse().error)
    return request.post(`${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/cv/take-action/${config?.workflow_id}/${config?.identifier}`, {
        ...config?.data
    }, {
        headers: {
            ['enforce-language']: config?.language,
            ['content-type']: "multipart/form-data"
        }
    })
}
