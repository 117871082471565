import React from "react";
import {Props} from "./interface";
import styles from './TextLabel.module.css'
import {getImgPath, getSizes} from "../../../../helpers/functions";

const TextLabel = (props: Props) => {

    /************************************
     *        INLINE STYLES
     * *********************************/
    const containerStyle = {
        "--text-font-weight": props.config.text?.style?.bold ? "bold" : "normal",
        "--text-font-size": getSizes(props.config.text?.style?.size),
        "--text-font-color": props.config.text.color,
        "--text-font-align": props.config.text?.align,
    } as React.CSSProperties
    return (
        <div
            className={`${styles.container}`}
            style={containerStyle}
        >
            <div className={`${styles.flexContainer}`} >
                <div >{props.config.text.icon && <img style={{width:"23px"}} src={getImgPath(props.config.text.icon)} />}</div>
                <div className={`ms-1`}>{props.config.text.locale}</div>
            </div>
        </div>
    )
}

export default TextLabel