import React, {useState} from "react";
import styles from './Score.module.css'
import {Props,RefDataType} from './interfaces';
import {Slider, Typography} from "@mui/material";
import {getSizes} from "../../../../helpers/functions";

const Score = React.forwardRef<RefDataType, Props>((props, ref) => {


    const [value, setValue] = useState(1)

    {/*HANDLE CHANGING*/}
    const handleChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setValue(newValue);
        }
    };

    {/*STYLING COMPONENT */}
    const titleStyle = {
        fontWeight: props.config.title.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.title.style?.size) + "px",
        fontColor: props.config.title.color
    } as React.CSSProperties

    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.header}`}>
                <Typography id="non-linear-slider" gutterBottom>{value}%</Typography>
                <Typography style={titleStyle} id="non-linear-slider" gutterBottom
                            className={'label'}>{props.config.title.locale}</Typography>
            </div>

            <Slider
                value={value}
                min={1}
                step={10}
                max={100}
                onChange={handleChange}
                valueLabelDisplay="auto"
                className={`${styles.slider}`}
                aria-labelledby="non-linear-slider"
                sx={{
                    color: '#44bd0f',
                    '& .MuiSlider-thumb': {
                        border: "1px solid #00b9d2",
                        backgroundColor: "#fff"
                    },
                }}
                marks
            />
            <ul className={`${styles.items}`}></ul>
        </div>
    )
})

export default Score