import styles from "./DisconnectInternet.module.css"
import {
    MdSignalWifiOff
} from "react-icons/md";
import {useTranslation} from "react-i18next";


interface Props {
    className?: string
}


const DisconnectInternet = (props: Props) => {
    const {t} = useTranslation();


    return (
        <div className={`${styles.container}`}>
            <MdSignalWifiOff style={{width: "10%", height: "10%", color: "gray"}}></MdSignalWifiOff>
            <h1 style={{opacity: "0.8"}}>
                {t("Oops!")}
            </h1>
            <h3 style={{opacity: "0.7"}}>{t('noInternetConnection')}</h3>
            <p style={{opacity: "0.5"}}>{t("pleaseCheckInternetConnection")} </p>
        </div>
    )
}

export default DisconnectInternet;