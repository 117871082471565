import DynamicObject from "../../../../models/dynamic-object";

export function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

export function formatDate(date: Date) {
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-')
    );
}

export function toDateObject(date: string | undefined) {
    if (typeof date === 'undefined') return {}
    const dateSegments = date.split('-')
    return {
        year: Number(dateSegments[0]),
        month: Number(dateSegments[1]),
        day: Number(dateSegments[2])
    }
}

export function fromDateObject(date: DynamicObject) {
    return date.year + '-' + date.month + '-' + date.day
}