import ArachnaApi from './requests/arachna-api'
import {AxiosInstance, AxiosProgressEvent, AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    return ArachnaApi.getInstance().request.post(`${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/cv/normal/drafts/media/${config?.draftId}`,
        config?.data, {
            params: {
                abBucket: config?.bucket
            }
        })
}