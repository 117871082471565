import React from "react";
import {Props,RefDataType} from './interfaces';
import styles from "./Toggle.module.css";
import {getSizes} from "../../../../helpers/functions";
import {Switch} from "@mui/material";
const Toggle = React.forwardRef<RefDataType, Props>((props, ref) => {

    const containerStyle = {
        "--text-font-weight": props.config.text?.style?.bold ? "bold" : "normal",
        "--text-font-size": getSizes(props.config.text?.style?.size) + "px",
        "--text-font-color": props.config.text.color,
        "--text-font-align": props.config.text?.align,
    } as React.CSSProperties

    return (<div className={`row ${styles.container}`} style={containerStyle}>
        <div className={`col-9 `}>{props.config.text.locale}</div>
        <div className={`col-3`}>
            <div className={`${styles.float_right}`}>
                <Switch size={"medium"}></Switch>
            </div>
        </div>
    </div>)

})

export default Toggle
