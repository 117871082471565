import React from "react";
import styles from './CardSlider.module.css'
import {ItemProps as Props} from "./interface"
import {getSizes} from "../../../../helpers/functions";


const Item = (props:Props) => {

    /************************************
     *       INLINE TITLE STYLE
     * *********************************/
    const titleStyle = {
        fontWeight: props.info.title.style?.bold?'fw-bold':'',
        fontSize:  getSizes(props.info.title.style?.size),
        color: props.info.title.color,
    } as React.CSSProperties

    /************************************
     *       INLINE SUBTITLE STYLE
     * *********************************/
    const subTitleStyle = {
        fontWeight: props.info.subTitle.style?.bold?'fw-bold':'',
        fontSize:  getSizes(props.info.subTitle.style?.size),
        color: props.info.subTitle.color,
    } as React.CSSProperties


    return (
        <div>
            <div className={`${styles.container} rounded`} style={{background: props.info.background?.color}}>
                <div className={`row ${styles.card_row}`}>
                    <div className={`col-md-8`}>
                        <h2 style={titleStyle} >{ props.info.title.locale}</h2>
                        <p className={`${styles.description}`}
                           style={subTitleStyle}>{props.info.subTitle.locale} </p>
                    </div>

                    <div className={`col-md-4 ${styles.img_container}`}>
                        <div className={styles.img}>
                            <img  style={{width: 100,}}src={props.info.imagePath}
                                 className={`rounded-circle img-fluid`}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Item