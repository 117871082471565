export default class ActiveRequests {
    static instance: ActiveRequests;
    private _uploadsCounter: number

    private constructor() {
        this._uploadsCounter = 0
    }

    uploadsCounter(): number {
        return this._uploadsCounter;
    }

    private fireEvent() {
        window.dispatchEvent(new CustomEvent('upload-requests', {detail: this._uploadsCounter}))
    }

    public increase() {
        this._uploadsCounter++
        this.fireEvent()
    }

    public decrease() {
        this._uploadsCounter--
        this.fireEvent()
    }


    static getInstance() {
        if (!this.instance)
            this.instance = new ActiveRequests()

        return this.instance
    }
}