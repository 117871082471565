import BaseApi from "./base-api";
import {Config} from "./types";
import {DefaultArachnaRequestInterceptor} from "../../interceptors";
import DefaultArachnaResponseInterceptor from "../../interceptors/default-arachna-response.interceptor";

export default class ArachnaApi extends BaseApi{

    protected configuration () : Config {
        return {
            baseURL: process.env.REACT_APP_ARACHNA_API_URL,
            timeOut: (process.env.REACT_APP_API_TIMEOUT || 5000) as number
        }
    }

    defaultRequestInterceptors(): any[] {
        return [
            DefaultArachnaRequestInterceptor
        ];
    }

    defaultResponseInterceptors(): any[] {
        return [
            DefaultArachnaResponseInterceptor
        ];
    }
}