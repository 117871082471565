import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import env from "ts-react-dotenv";
import {ResponseInterceptorType, RequestInterceptorType, Config} from './types'

export default abstract class BaseApi {
    private static obj: any
    public request?: AxiosInstance
    protected config?: Config

    protected configuration(): Config {
        return {
            baseURL: "http://localhost",
            timeOut: env.API_TIMEOUT || 5000
        }
    }

    protected init() {
        this.config = this.configuration()

        this.request = axios.create({
            baseURL: this.config?.baseURL
        })

        const requestInterceptors = this.defaultRequestInterceptors()
        const responseInterceptors = this.defaultResponseInterceptors()

        for (const requestInterceptor of requestInterceptors)
            this.request?.interceptors.request.use(requestInterceptor)

        for (const responseInterceptor of responseInterceptors){
            const interceptor = responseInterceptor()
            this.request?.interceptors.response.use(interceptor.success, interceptor.error)

        }
    }

    abstract defaultRequestInterceptors(): any[]

    abstract defaultResponseInterceptors(): any[]

    protected constructor() {}

    static getInstance() {

        if (!this.obj) {
            this.obj = new (<any>this)();
        }

        this.obj.init()
        return this.obj
    }
}