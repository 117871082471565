import React from "react";
import styles from "./PhoneField.module.css";
import MenuItem from "@mui/material/MenuItem";
import {PhoneFieldCodeProps} from "./interface";
import {Select, SelectChangeEvent} from "@mui/material";

const PhoneFieldCode = (props: PhoneFieldCodeProps): JSX.Element => {

    const {country, state, addCountryHandler} = props

    /************************************
     *   INITIAL COUNTRY CODE & FLAG
     * *********************************/
    if (country.values.length <= 1) {
        return (
            <div className={`${styles.img_label}`}>
                <div className={`${styles.item}`}>
                    <img className={`${styles.image}`} src={country.values[0].icon}/>
                </div>
                <span style={{marginTop: "2px", fontSize: "15px"}}> {country.values[0].phone_code}</span>
            </div>
        )
    }


    /************************************
     *      COUNTRY CODE & FLAG
     * *********************************/
    const countries = country?.values.map((country, i) => {
        return <MenuItem value={country.abbreviation}>
            <div className={`${styles.img_label}`}>
                <div className={`${styles.item}`}>
                    <img className={`${styles.image}`} src={country.icon}/>
                </div>
                <span style={{marginTop: "2px", fontSize: "15px"}}> {country.phone_code}</span></div>
        </MenuItem>
    })

    return (
        <div className={`${styles.header}`}>
            <Select labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={state}
                    onChange={(e: SelectChangeEvent) => addCountryHandler(e)}
                    sx={{boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': {border: 0}}}>
                {countries}
            </Select>
        </div>
    )

}

export default PhoneFieldCode