import ArachnaApi from './requests/arachna-api'
import {AxiosInstance, AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";
import {AddPostFilterResponse} from "../interceptors";

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    const request = ArachnaApi.getInstance().request as AxiosInstance
    request.interceptors.response.use(AddPostFilterResponse().success,  AddPostFilterResponse().error)


    return request.post(`${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/add-post/redirect/${config?.data.currentStep}/${config?.data.workflowId}`, {
        draftId: config?.data.draftId,
        time_spent_ms: config?.data?.time_spent_ms,
        categoriesMain: config?.data?.categoriesMain,
        redirect_id: config?.data?.redirect_id,
    })
}