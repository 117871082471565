type CallbacksType = {
    [key: string]: (payload) => void
}


export class IFrameEvents {

    static callbacks: CallbacksType = {}

    static callback(event) {

        if (this.callbacks[event.data?.action]) {
            this.callbacks[event.data?.action](event.data.payload)
        }
    }

    static start() {
    }

    static dispatch(action: string, payload: any) {
        window?.parent?.postMessage?.({action, payload}, '*')
    }

    static listen(action: string, callback: (payload) => void) {
        window.removeEventListener("message", this.callback);
        this.callbacks[action] = callback
        window.addEventListener("message", this.callback);

    }

    static unlisten(action: string) {
        window.removeEventListener("message", this.callback);
        delete this.callbacks[action]
        window.addEventListener("message", this.callback);
    }


}