import {Libraries} from "@googlemaps/js-api-loader";

interface Config {
    key: string,
    libraries: Libraries
}

const config : Config = {
    key: "AIzaSyA0UlWZ3NY3KwR7IkKrR0-RdKRDWkdEPto",
    libraries: []
}

export default config