import React from "react";
import {Props,RefDataType} from './interfaces';
import styles from './TextLabelWithAction.module.css'
import {getSizes} from "../../../../helpers/functions";

const TextLabel = React.forwardRef<RefDataType, Props>((props, ref) => {

    const containerStyle = {
        "--text-font-weight": props.config.text?.style?.bold ? "bold" : "normal",
        "--text-font-size": getSizes(props.config.text?.style?.size) + "px",
        "--text-font-color": props.config.text.color,
        "--text-font-align": props.config.text?.align,
    } as React.CSSProperties

    return (
        <div
            className={`${styles.container}`}
            style={containerStyle}
        >
            <p>{props.config.text.locale}</p>
        </div>
    )
})

export default TextLabel