import RefType from "../../models/ref-type";
import DynamicObject from "../../models/dynamic-object";
import {ForwardedRef, useImperativeHandle, useState} from "react";


interface RefDataType extends RefType<DynamicObject> {
}

interface Obj {
    ref: ForwardedRef<RefDataType>
}

const useValidationNew = () => {
    /* ERRORS STATE*/
    const [error, setError] = useState<string>('')
    const clearValidationError = () => {
        if (error)
            setError('')
    }

    return {
        setValidationError: (error: string) => {
            setError(error)
        },
        clearValidationError,
        error
    }

}
export default useValidationNew;