import {AxiosError, AxiosResponse} from "axios";
import {parentCallBack} from "../helpers/functions";
import {OK} from "../constants/Status";

interface Result {
    success: (response: AxiosResponse) => AxiosResponse,
    error: (error: AxiosError) => AxiosError,
}

export default (): Result => {
    return {
        success: (response: AxiosResponse) => {
            parentCallBack(OK)
            return response
        },
        error: (error: AxiosError) => {
            parentCallBack(error?.response?.status)
            return error
        }
    }
}