import React, {useContext, useImperativeHandle, useState} from "react";
import {Props, RefDataType} from "./interface";
import {useTranslation} from "react-i18next";
import {FaTimesCircle} from "react-icons/fa";
import styles from './ListCheckboxOptions.module.css'
import PostContext from "../../../../storage/PostContext";
import DynamicObject from "../../../../models/dynamic-object";
import useSearch from "../../../../hooks/use-search/use-search";
import languageContext from "../../../../storage/LanguageContext";
import {getItemsAsString, getSizes} from "../../../../helpers/functions";
import useValidationNew from "../../../../hooks/use-validation/use-validation-new";
import {Checkbox, FormControlLabel, FormGroup, FormHelperText, Typography} from "@mui/material";

const ListCheckboxOptions = React.forwardRef<RefDataType, Props>((props, ref) => {

    const {config} = props


    /************************************
     *           CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)
    const langCtx = useContext(languageContext)


    /*-------------------------------------
    *              HOOKS
    * ----------------------------------*/

    /************************************
     *     GET AND CLEAR VALIDATION
     * *********************************/
    const {error, setValidationError, clearValidationError} = useValidationNew()

    /************************************
     *      CUSTOME SEARCH HOOK
     * *********************************/
    const {filteredResults, Search} = useSearch({
        items: config.values,
        search: {
            placeholder: config?.search?.placeholder?.locale
        }
    })
    /*------------------------------------*/

    /************************************
     *          TRANSLATION
     * *********************************/
    const {t} = useTranslation();

    /************************************
     *           CONSTANTS
     * *********************************/
    const alignCenter = (langCtx.language == 'en') ? 'left' : 'right'


    /*-------------------------------------
     *            FUNCTIONS
     * ----------------------------------*/

    /************************************
     *        HELPER FUNCTIONS
     * *********************************/
    const getItemInfo = (id: number | string) => {
        return config?.values?.find(item => item.id == id)
    }

    const prepareData = () => {
        const items: DynamicObject = {}
        const dataItems = postCtx.data.form[config.identifier] ? postCtx.data.form[config.identifier]?.split(',') ?? [] : []
        if (dataItems.length > 0) {
            dataItems.map((value: any) => {
                const item: any = getItemInfo(value)
                items[`${item.id}` as string] = item.label
            })
        }
        return items
    }
    /************************************
     *             STATES
     * *********************************/
    const [checkedValues, setCheckedValues] = useState<DynamicObject>(prepareData)

    /************************************
     *         ACTIONS HANDLER
     * *********************************/
    const addItemHandler = (item: DynamicObject) => {
        setCheckedValues(old => {
            return {
                ...old,
                [`${item.id}`]: item.label
            }
        })
    }

    const removeItemHandler = (item: DynamicObject) => {
        setCheckedValues(old => {
            const state = {...old}

            delete state[item.id]

            return state
        })
    }

    const changeItem = (e: React.ChangeEvent<HTMLInputElement>, item: DynamicObject) => {
        clearValidationError()
        if (!e.target.checked) {
            removeItemHandler(item)
            return
        }
        addItemHandler(item)
    }

    /*------------------------------------*/
    /************************************
     *   GET INNER STATE USING REFS
     ************************************/
    useImperativeHandle<RefDataType, RefDataType>(ref, () => {
        return {
            getState: (): any => Object.keys(checkedValues).join(','),
            setValidationError
        }
    })


    return (
        <div>
            {config?.search &&
                <Search></Search>
            }
            {config.tags && <div className={`row ${styles.checked_container}`}>
                {
                    config.values.map((item) => {
                        if (!checkedValues[item.id])
                            return

                        return (<div className={`${styles.checked_item_box}`}>
                            <div className={styles.checked_item_box_container}>
                                <span>{item.label}</span>
                                <FaTimesCircle className={`cursor__pointer`}
                                               onClick={(e: React.MouseEvent) => removeItemHandler(item)}/>
                            </div>
                        </div>)
                    })
                }
            </div>
            }
            {error && <FormHelperText style={{
                color: "red",
                fontSize: "15px",
                paddingTop: "2px",
                textAlign: alignCenter
            }}>{`*   ${error}`}</FormHelperText>
            }
            <div className={`row mt-1 `}>

                <div className={`${styles.container}`} style={{overflowX: "hidden"}}>
                    <div className={`rounded fw-bold `}>
                        {
                            filteredResults.length < 1 && (
                                <div className={`row ${styles.no_found_data}`}>
                                    <h3>
                                        {t('noResults')}
                                    </h3>
                                </div>
                            )
                        }
                        {
                            filteredResults.map((item, i) => {
                                return <div className={`${styles.item}`}>
                                    <FormGroup>
                                        <FormControlLabel
                                            sx={{padding: "10px"}}

                                            control={
                                                <Checkbox
                                                    onChange={(e) => changeItem(e, item)}

                                                    checked={!!checkedValues[item.id]}
                                                />
                                            }
                                            label={
                                                <Typography sx={{
                                                    marginTop: "12px",
                                                    fontWeight: config.cell.label.style.bold ? 'bold' : 'normal',
                                                    fontSize: getSizes(config.cell.label.style.size),
                                                    color: config.cell.label.color
                                                }}>
                                                    {item.label}
                                                </Typography>
                                            } value={item.id}
                                        />
                                    </FormGroup>

                                </div>
                            })
                        }
                    </div>
                </div>


            </div>
        </div>
    )
})

export default ListCheckboxOptions