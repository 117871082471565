import React, {ChangeEvent, CSSProperties, useContext, useEffect, useImperativeHandle, useState} from "react";
import {Props} from "./interface";
import styles from './FieldWithUnit.module.css'
import {getInputType, getSizes, toEnDigit} from "../../../../helpers/functions";
import PostContext from "../../../../storage/PostContext";
import {RefDataType} from "../../Tools/TextField/interfaces";
import DynamicObject from "../../../../models/dynamic-object";
import languageContext from "../../../../storage/LanguageContext";
import {FormHelperText, Select, SelectChangeEvent, TextField} from "@mui/material";
import useValidationNew from "../../../../hooks/use-validation/use-validation-new";
import FieldWithUnitMenu from "../../PreviewTools/FieldWithUnit/FieldWithUnitMenu";
import MenuItem from "@mui/material/MenuItem";


const FieldWithUnit = React.forwardRef<RefDataType, Props>((props, ref) => {

    const {config} = props
    /************************************
     *           CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)
    const langCtx = useContext(languageContext)

    /*-------------------------------------
     *              HOOKS
     * ----------------------------------*/

    /************************************
     *  GET AND CLEAR VALIDATION ERROR
     * *********************************/
    const {error, setValidationError, clearValidationError} = useValidationNew()
    /************************************
     *           CONSTANTS
     * *********************************/
    const alignCenter = (langCtx.language == 'en') ? 'left' : 'right'
    const inputMinType = getInputType(props.config?.min?.format)
    const inputMaxType = getInputType(props.config?.max?.format)
    /************************************
     *             STATES
     * *********************************/
    const [value, setValue] = useState<DynamicObject>(postCtx.data.form[props.config.identifier])
    const [unit, setUnit] = useState<number | string>(postCtx.data.form[config.identifier]?.unit)
    const [minUnit, setMinUnit] = useState<string>(postCtx.data.form[config.identifier]?.min)
    const [maxUnit, setMaxUnit] = useState<string>(postCtx.data.form[config.identifier]?.max)

    /************************************
     *   PREVENT DECREASING AND INCREASING
     *         IN SCROLLING
     * *********************************/
    document.addEventListener("wheel", function (event) {
        document.getElementById("outlined-number")?.blur()

    });


    useEffect(function () {
        if (props.config?.units?.length > 0 && !unit) {
            setUnit(props.config.units[0].id)

        }
    }, [props.config?.units])


    /*---------------------------------------------------------
     *                         FUNCTIONS
     * -------------------------------------------------------*/

    /***********************************************
     *         UNIT HANDLER
     * @use on change update state & context
     * *********************************************/

    const addUnitHandler = (e: SelectChangeEvent<number | string>) => {
        clearValidationError()
        setUnit(e.target.value)
        postCtx.addToForm(config.identifier, {...postCtx.data.form[config.identifier], unit: e.target.value})
    }

    /************************************************
     * GET INNER STATE USING IMPERATIVE HANDLER(REFS)
     * **********************************************/
    useImperativeHandle<RefDataType, RefDataType>(ref, () => {
        return {
            getState: (): any => value,
            setValidationError
        }
    })

    /************************************************
     *       MIN HANDLER ON CHANGE
     * @use on change update state.
     * **********************************************/
    const minUnitHandler = (e: ChangeEvent<HTMLInputElement>) => {
        clearValidationError()
        if (props.config?.min?.format == 'float') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*\.?[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*$/g.test(e.target.value) || e.target.value == '') {
                setMinUnit(toEnDigit(e.target.value))

            }
        } else if (props.config?.min?.format == 'int') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]+$/g.test(e.target.value) || e.target.value == '') {
                setMinUnit(toEnDigit(e.target.value))

            }
        } else {
            setMinUnit(toEnDigit(e.target.value))
        }


    }

    // const minUnitOnBlurHandler = () => {
    //     postCtx.addToForm(props.config.identifier, {...postCtx.data.form[config.identifier], min: minUnit})
    // }


    /************************************************
     *       MAX HANDLER ON CHANGE
     * @use on change update state.
     * **********************************************/
    const maxUnitHandler = (e: ChangeEvent<HTMLInputElement>) => {
        clearValidationError()

        if (props.config?.max?.format == 'float') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*\.?[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*$/g.test(e.target.value) || e.target.value == '') {
                setMaxUnit(toEnDigit(e.target.value))

            }
        } else if (props.config?.max?.format == 'int') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]+$/g.test(e.target.value) || e.target.value == '') {
                setMaxUnit(toEnDigit(e.target.value))

            }
        } else {
            setMaxUnit(toEnDigit(e.target.value))

        }
    }
    // const maxUnitOnBlurHandler = () => {
    //     postCtx.addToForm(props.config.identifier, {...postCtx.data.form[config.identifier], max: maxUnit})
    // }

    const handleMinKey = (event) => {
        let regExp = new RegExp(/[\u0660-\u0669\u06f0-\u06f9]/g)
        if (props.config?.min?.format == 'float' && (event.which >= 48 && event.which <= 57 || event.which == 46 || regExp.test(event.key))) {
            if (event.target?.value.includes('.') && event.which == 46) {
                event.preventDefault()
            }
            setMinUnit(toEnDigit(event.target.value));
        } else if (props.config?.min?.format == 'int' && (event.which >= 48 && event.which <= 57 || regExp.test(event.key))) {
            setMinUnit(toEnDigit(event.target.value));
        } else if (props.config?.min?.format == 'all' || props.config?.min?.format=='open') {
            setMinUnit(toEnDigit(event.target.value));
        } else {
            event.preventDefault();
        }
    }

    const handleMaxKey = (event) => {
        let regExp = new RegExp(/[\u0660-\u0669\u06f0-\u06f9]/g)
        if (props.config?.max?.format == 'float' && (event.which >= 48 && event.which <= 57 || event.which == 46 || regExp.test(event.key))) {
            if (event.target?.value.includes('.') && event.which == 46) {
                event.preventDefault()
            }
            setMaxUnit(toEnDigit(event.target.value));
        } else if (props.config?.max?.format == 'int' && (event.which >= 48 && event.which <= 57 || regExp.test(event.key))) {
            setMaxUnit(toEnDigit(event.target.value));
        } else if (props.config?.max?.format == 'all' || props.config?.max?.format == 'open') {
            setMaxUnit(toEnDigit(event.target.value));
        } else {
            event.preventDefault();
        }
    }
    /************************************************
     *              INLINE MIN STYLE
     * **********************************************/
    const minStyle = {
        color: config?.min?.placeholder.color,
        fontWeight: config?.min?.placeholder.style?.bold ? 'bold' : 'normal',
        fontSize: getSizes(config?.min?.placeholder.style?.size),
    } as CSSProperties

    /************************************************
     *              INLINE MAX STYLE
     * **********************************************/
    const maxStyle = {
        color: config?.min?.placeholder.color,
        fontWeight: config?.min?.placeholder.style?.bold ? 'bold' : 'normal',
        fontSize: getSizes(config?.min?.placeholder.style?.size),
    } as CSSProperties

    /************************************************
     *              GET MENU ITEMS
     * **********************************************/
    const units = config.units.map((unit, i) => {
        return <MenuItem value={unit.id}>
            <span> {unit.label}</span>
        </MenuItem>
    })


    const header = <div className={`${styles.header}`}>
        <Select labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={parseInt(unit?.toString())}
                onChange={(e: SelectChangeEvent<number>) => addUnitHandler(e)}
                sx={{boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': {border: 0}}}>
            {units}
        </Select>
    </div>

    const min = <TextField className={`${styles.min}`} id="outlined-number"
                           placeholder={config.min?.placeholder.locale}
                           value={minUnit}
                           onChange={minUnitHandler}
        // onBlur={minUnitOnBlurHandler}
                           InputLabelProps={{sx: minStyle}}
                           inputMode={inputMinType}
                           onPaste={(event) => {

                               if (!(props.config?.min?.format == 'all' || props.config?.min?.format == 'open') ) {
                                   event.preventDefault()
                               }
                           }}
                           onCopy={(event) => {
                               if (!(props.config?.min?.format == 'all' || props.config?.min?.format == 'open') ) {
                                   event.preventDefault()
                               }
                           }}
                           onCut={(event) => {

                               if (!(props.config?.min?.format == 'all' || props.config?.min?.format == 'open') ) {
                                   event.preventDefault()
                               }
                           }}
                           autoComplete={"off"}
                           autoCorrect={"off"}
                           spellCheck={"false"}
                           onKeyPress={handleMinKey}/>

    const max = <TextField className={`${styles.max}`} id="outlined-number"
                           placeholder={config.max?.placeholder.locale}
                           onChange={maxUnitHandler}
        // onBlur={maxUnitOnBlurHandler}
                           InputLabelProps={{sx: maxStyle}}
                           value={maxUnit}
                           inputMode={inputMaxType}
                           onPaste={(event) => {
                               if (!(props.config?.max?.format == 'all' || props.config?.max?.format == 'open') ) {
                                   event.preventDefault()
                               }

                           }}
                           onCopy={(event) => {
                               if (!(props.config?.max?.format == 'all' || props.config?.max?.format == 'open') ) {
                                   event.preventDefault()
                               }
                           }}
                           onCut={(event) => {

                               if (!(props.config?.max?.format == 'all' || props.config?.max?.format == 'open') ) {
                                   event.preventDefault()
                               }
                           }}
                           autoComplete={"off"}
                           autoCorrect={"off"}
                           spellCheck={"false"}
                           onKeyPress={handleMaxKey}/>

    return (
        <div
            className={`${styles.container}`}>
            {config.units.length > 1 ? header : config.units.length == 1 ? <div className={`${styles.header}`}>
                <span> {props.config.units[0].label}</span>
            </div> : ''}
            <div className={`${styles.body}`}>
                {config.min && min}
                {config.max && max}
            </div>

            {error && <FormHelperText style={{
                color: "red",
                fontSize: "15px",
                paddingTop: "2px",
                textAlign: alignCenter

            }}>
                {`*  ${error}`}
            </FormHelperText>}
        </div>
    )
})

export default FieldWithUnit


