import {
    DefaultArbokRequestInterceptor,
} from "../../interceptors";
import BaseApi from "./base-api";
import {Config, RequestInterceptorType, ResponseInterceptorType} from "./types";
import env from "ts-react-dotenv";

export default class ArbokApi extends BaseApi{

    protected configuration () : Config {
        return {
            baseURL: process.env.REACT_APP_ARBOK_URL,
            timeOut: parseInt(process.env.API_TIMEOUT || "5000")
        }
    }

    defaultRequestInterceptors(): any[] {
        return [
            DefaultArbokRequestInterceptor
        ];
    }

    defaultResponseInterceptors(): any[] {
        return [
        ];
    }
}