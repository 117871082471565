import {Button as ButtonModel} from "../../../../models";
import styles from "./ConfirmationModel.module.css"
import {Button as MuiButton} from "@mui/material";
import {getSizes} from "../../../../helpers/functions";
import React, {useState} from "react";

interface Props {
    config: ButtonModel;
    buttonActionHandler: (event: React.MouseEvent<HTMLElement>, value: boolean) => void


}

const ConfirmationModel = (props: Props) => {

    const {config, buttonActionHandler} = props


    const titleStyle = {
        fontWeight: config.confirmation?.title?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.confirmation?.title?.style?.size),
        color: config.confirmation?.title?.color,
        textAlign: config.confirmation?.title?.align,
    } as React.CSSProperties

    const buttonYesStyle = {
        fontWeight: config.confirmation?.yes?.text?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.confirmation?.yes?.text?.style?.size),
        color: config.confirmation?.yes?.text?.color,
        textAlign: config.confirmation?.yes?.text?.align,
    } as React.CSSProperties

    const buttonNoStyle = {
        fontWeight: config.confirmation?.no?.text?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.confirmation?.no?.text?.style?.size),
        color: config.confirmation?.no?.text?.color,
        textAlign: config.confirmation?.no?.text?.align,
    } as React.CSSProperties

    return (
        <div>
            <p style={titleStyle}>{config.confirmation?.title.locale}</p>
            <div className={`${styles.container}`}>
                <MuiButton style={buttonYesStyle} fullWidth={true} variant={"outlined"}
                           onClick={(event) => buttonActionHandler(event, true)}>{config.confirmation?.yes.text.locale}</MuiButton>

                <MuiButton style={buttonNoStyle} fullWidth={true} variant={"outlined"}
                           onClick={(event) => buttonActionHandler(event, false)}>{config.confirmation?.no.text.locale}</MuiButton>

            </div>
        </div>

    )


}

export default ConfirmationModel