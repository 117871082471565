import React from "react";
import Item from "./Item";
import {Props, RefDataType} from './interfaces';
import Carousel from 'react-material-ui-carousel'

const CardSlider = React.forwardRef<RefDataType, Props>((props, ref) => {

    return (<div style={{width: "100%"}}>
        <Carousel navButtonsAlwaysInvisible={true}>
            {
                props.config.cards.map((item, i) => <Item info={item}/>)
            }
        </Carousel>
    </div>)
})
export default CardSlider















