import React from "react";
import {Button as MuiButton} from "@mui/material";
import styles from "./ConfirmationModal.module.css"
import {getSizes} from "../../../../helpers/functions";
import {ConfirmationModalProps as Props} from "./interface";

const ConfirmationModal = (props: Props) => {

    const {config, buttonActionHandler} = props


    /*-------------------------------------
     *    INLINE BUTTON ACTIONS STYLES
     * ----------------------------------*/
    const buttonYesStyle = {
        fontWeight: config.confirmation?.yes?.text?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.confirmation?.yes?.text?.style?.size),
        color: config.confirmation?.yes?.text?.color,
        textAlign: config.confirmation?.yes?.text?.align,
    } as React.CSSProperties

    const buttonNoStyle = {
        fontWeight: config.confirmation?.no?.text?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.confirmation?.no?.text?.style?.size),
        color: config.confirmation?.no?.text?.color,
        textAlign: config.confirmation?.no?.text?.align,
    } as React.CSSProperties

    return (
        <div className={`${styles.container}`}>
            <MuiButton style={buttonYesStyle} fullWidth={true} variant={"outlined"}
                       onClick={(event) => buttonActionHandler(event, true)}>{config.confirmation?.yes.text.locale}</MuiButton>

            <MuiButton style={buttonNoStyle} fullWidth={true} variant={"outlined"}
                       onClick={(event) => buttonActionHandler(event, false)}>{config.confirmation?.no.text.locale}</MuiButton>

        </div>
    )


}
export default ConfirmationModal