import {useContext, useState} from "react";
import {AxiosResponse, AxiosError} from "axios";
import {UseHttpResponse, UseHttpConfig, HttpState, Args} from './interfaces'
import UIContext from "../../storage/UIContext";


const useHttp = <T extends unknown | {}>(config: UseHttpConfig<T>): UseHttpResponse<T> => {


    const httpInitial: HttpState<T> = {
        data: config.initialData,
        request: {
            config: {},
            /* callback:{
                 success: () ,
                 error: () ,
             }*/
        },
    }

    const uiCtx = useContext(UIContext)

    const [http, setHttp] = useState(httpInitial);


    const request = (args: Args = {}) => {
        const {config: httpConfig}: Args = args

        if (args.callbacks?.before)
            args.callbacks.before()


        config.callback(httpConfig)
            .then((res: AxiosResponse) => {

                setHttp({
                    data: res.data,
                    request: {
                        config: httpConfig,
                    },
                })

                if (args.callbacks?.success)
                    args.callbacks.success(res)

            }).catch((error: AxiosError) => {
            if (!error.response) {
                // uiCtx.setOnline(false)
            }
            if (args.callbacks?.error) {
                args.callbacks.error(error)
            }
        })
    }


    return {
        data: http.data,
        request,
        refresh: () => {
            request({
                config
            })
        }
    }
}

export default useHttp