import React from "react";
import {Props} from "./interfaces";
import {Button} from "@mui/material";
import styles from './ButtonContainer.module.css'
import {getSizes} from "../../../../helpers/functions";



const ButtonContainer = (props: Props) => {

    /************************************
     *       INLINE TITLE STYLE
     * *********************************/
    const titleStyle = {
        fontWeight: props.config.title?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.title?.style?.size),
        color: props.config.title?.color,
    } as React.CSSProperties

    /************************************
     *       INLINE SUBTITLE STYLE
     * *********************************/
    const subTitleStyle = {
        fontWeight: props.config.subTitle?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.subTitle?.style?.size),
        color: props.config.subTitle?.color,
    } as React.CSSProperties


    return (
        <div className={`${styles.container}`}
             >
            <div className={`${styles.box} shadow`}>
                <div className={`${styles.header}`}>
                    <h1 style={titleStyle}>{props.config.title?.locale}</h1>
                </div>
                <div className='row'>
                    <div className='col-md-12 mb-2 '>
                        <Button style={subTitleStyle} variant="outlined" color="inherit" fullWidth={true}>
                            {props.config.subTitle?.locale}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ButtonContainer