import {AxiosResponse} from "axios";
import ArachnaApi from './requests/arachna-api'
import DynamicObject from "../models/dynamic-object";
import {AddPostFilterResponse} from "../interceptors";

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    const request = ArachnaApi.getInstance().request
    request.interceptors.response.use(AddPostFilterResponse().success, AddPostFilterResponse().error)

    return request.get(`${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/cv/normal/1`, {})
}
